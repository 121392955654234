import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastSuccess, ToastError } from "../../views/common/Toast";

/***
 * Profile Reducer initial state
 * ***/
const initialProfileState = { isLoading: false, isError: "", profileRes: null, postData: null, lastcalled: false, isUpdating: false };

const profileSlice = createSlice({
    name: "profile",
    initialState: initialProfileState,
    reducers: {
        /**
         * Profile GET API Reducers
         * **/
        GetApiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        GetApiCallSuccess(state, action) {
            state.isLoading = false;
            state.profileRes = action.payload;
			//console.log("payload", action.payload);
        },
        GetApiCallRemove(state, action) {
            state.isLoading = false;
            state.profileRes = null;
        },
        GetApiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        apiSetTime(state) {
          state.lastcalled = new Date();
        },

        /**
        * Profile POST API Reducers
        * **/

        PostApiCallRequested(state) {
            state.isLoading = true;
            state.isUpdating = true;
            state.isError = "";
        },
        PostApiCallSuccess(state, action) {
            state.isLoading = false;
            state.isUpdating = false;
            state.postData = action.payload
        },
        PostApiCallFailed(state, action) {
            state.isLoading = false;
            state.isUpdating = false;
            state.isError = action.payload;
        },
        resetLoading(state) {
            state.isLoading = false;
            state.postData = null;
        }

    },
});

/***
 * Profile GET API
 * ***/
export const profileGetAPI = (profileRes) => {
    return async (dispatch, getState) => {
        let currentlyLoading = getState()?.ProfileRes?.isLoading;
        if(currentlyLoading === false){
            const token = getState()?.LoginRes?.authResToken;
            dispatch(profileActions.GetApiCallRequested());
            dispatch(profileActions.apiSetTime());

            //let profileResL = getState()?.ProfileRes?.profileRes;
            //console.log('here profileRes', profileRes);
            let formData = {
                command: "profile"
            };
            try {
                const res = await axios({
                    method: "POST",
                    //url: '/tenant/info',
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const { errors, message, data } = res?.data;
                if (!errors) {
                    dispatch(profileActions.GetApiCallSuccess(data));
                } else {
                    throw message;
                }

            } catch (error) {
                dispatch(profileActions.GetApiCallFailed(error));
                console.log(error)
            }
        }
    }
};

/***
 * Profile POST API
 * ***/
export const profilePostAPI = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        let currentlyUpdating = getState()?.ProfileRes?.isUpdating;
        let formData = {
            command: "profile-update",
            ...data
        };
        if(currentlyUpdating === false){
            dispatch(profileActions.PostApiCallRequested());
            try {
                const res = await axios({
                    method: "POST", 
                    //url: `/tenant/update`, 
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const { errors, message, data } = res?.data;
                if (!errors) {
                    ToastSuccess(message);
                    dispatch(profileGetAPI());
                    dispatch(profileActions.PostApiCallSuccess(data));
                } else {
                    throw message;
                }
            } catch (error) {
                console.log(error);
                ToastError("Unable to connect to API");
                dispatch(profileActions.PostApiCallFailed(error));
            }
        }
    }
};

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
